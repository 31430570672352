export function websocketMessage(
  message: string,
  data?: Record<string, string>
) {
  if (data) {
    return `[5, "${message}", ${JSON.stringify(data)}]`;
  }
  return `[5, "${message}"]`;
}

export function subscribeMessage(topic: string, auth: string) {
  return websocketMessage(topic, { authentication: auth });
}

export function unsubscribeMessage(topic: string) {
  return websocketMessage(topic, { action: "unsubscribe" });
}
